<template>
  <div>
    <div>
      <div>
        <div>
          <div class="widget-content widget-content-area">
            <div class="table-responsive">
              <table
                class="table table-bordered w-100"
                id="payout-requests-table"
              >
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Reference</th>
                    <th>Status</th>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>Payable Account Name</th>
                    <th>Requesting User</th>
                    <th>Recommender</th>
                    <th>Manager</th>
                    <th>Created Date</th>
                    <th>Last Modified Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Create modal -->
      <b-modal
        id="create-payout-request"
        title="Make a Payout Request"
        size="xl"
        centered
      >
        <form
          method="POST"
          @submit.prevent="createPayout()"
          @keydown="payoutRequestForm.onKeydown($event)"
        >
          <AlertError :form="payoutRequestForm" />

          <b-row>
            <b-col
              sm="12"
              :md="isManager() || isAdmin() ? '7' : '12'"
              :lg="isManager() || isAdmin() ? '7' : '12'"
            >
              <b-form-group
                description="What category does your request fit into?"
                label="Category *"
                label-for="payout_request_sub_category_id"
                :invalid-feedback="
                  payoutRequestForm.errors.get('payout_request_sub_category_id')
                "
                :state="
                  !payoutRequestForm.errors.has(
                    'payout_request_sub_category_id'
                  )
                "
              >
                <b-form-select
                  id="payout_request_sub_category_id"
                  v-model="payoutRequestForm.payout_request_sub_category_id"
                  :options="payoutRequestSubCategories"
                  :state="
                    payoutRequestForm.errors.has(
                      'payout_request_sub_category_id'
                    )
                      ? false
                      : null
                  "
                  value-field="id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option value="" disabled>
                      -- Please select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                description="How much do you need?"
                label="Amount *"
                label-for="amount"
                :invalid-feedback="payoutRequestForm.errors.get('amount')"
                :state="!payoutRequestForm.errors.has('amount')"
              >
                <b-form-input
                  id="amount"
                  v-model="payoutRequestForm.amount"
                  placeholder="Enter the amount"
                  type="number"
                  min="100"
                  :state="payoutRequestForm.errors.has('amount') ? false : null"
                  trim
                ></b-form-input>
              </b-form-group>

              <b-form-group
                description="Tell us why you need this amount of money"
                label="Reason *"
                label-for="reason"
                :invalid-feedback="payoutRequestForm.errors.get('reason')"
                :state="!payoutRequestForm.errors.has('reason')"
              >
                <b-form-textarea
                  id="reason"
                  v-model="payoutRequestForm.reason"
                  placeholder="Enter your reason"
                  rows="2"
                  :state="payoutRequestForm.errors.has('reason') ? false : null"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                description="Do you have any attachments to support your request?"
                label="Attachments"
                label-for="attachments"
                :invalid-feedback="payoutRequestForm.errors.get('attachments')"
                :state="!payoutRequestForm.errors.has('attachments')"
              >
                <b-form-file
                  v-model="payoutRequestForm.attachments"
                  :state="
                    payoutRequestForm.errors.has('attachments') ? false : null
                  "
                  placeholder="Choose files or drop it here..."
                  drop-placeholder="Drop files here..."
                  accept="application/pdf, image/*"
                  :file-name-formatter="formatFileNames"
                  class="mb-3"
                  multiple
                ></b-form-file>
              </b-form-group>

              <b-form-group
                description="What department is responsible of giving the approval of this fund?"
                label="Recommending Department *"
                label-for="recommending_department"
                :invalid-feedback="
                  payoutRequestForm.errors.get('recommending_department')
                "
                :state="
                  !payoutRequestForm.errors.has('recommending_department')
                "
              >
                <b-form-select
                  id="recommending_department"
                  v-model="payoutRequestForm.recommending_department"
                  :options="departments"
                  :state="
                    payoutRequestForm.errors.has('recommending_department')
                      ? false
                      : null
                  "
                >
                  <template #first>
                    <b-form-select-option value="" disabled>
                      -- Please select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                description="Who in particular is expected to approve this fund?"
                label="Recommending User"
                label-for="recommending_user_id"
                :invalid-feedback="
                  payoutRequestForm.errors.get('recommending_user_id')
                "
                :state="!payoutRequestForm.errors.has('recommending_user_id')"
                v-if="users && users.length"
              >
                <b-form-select
                  id="recommending_user_id"
                  v-model="payoutRequestForm.recommending_user_id"
                  :options="users"
                  value-field="id"
                  text-field="name_description"
                  :state="
                    payoutRequestForm.errors.has('recommending_user_id')
                      ? false
                      : null
                  "
                >
                  <template #first>
                    <b-form-select-option value="">
                      -- Please select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="5"
              lg="5"
              v-if="isManager() || isAdmin() || isMedicalServices()"
            >
              <div class="border border-secondary p-2 mb-3">
                <div
                  class="d-md-flex justify-content-between align-items-center"
                >
                  <h6 class="mb-0">
                    Payout to
                    {{ payableToUser ? "user" : "third-party" }} account?
                  </h6>

                  <b-form-checkbox v-model="payableToUser">
                    Pay to user
                  </b-form-checkbox>
                </div>
                <small>Choose another for the payout</small>
              </div>

              <div v-if="payableToUser">
                <b-form-group
                  description="Choose the user's department"
                  label="Department"
                  label-for="payable_department"
                >
                  <b-form-select
                    id="payable_department"
                    v-model="payableDepartment"
                    :options="departments"
                  >
                    <template #first>
                      <b-form-select-option value="" disabled>
                        -- Please select an option --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  description="Choose the user"
                  label="Payable User"
                  label-for="payable_user"
                  v-if="payableUsers && payableUsers.length"
                >
                  <b-form-select
                    id="payable_user"
                    v-model="payableBankAccount"
                    :options="payableUsers"
                    value-field="bank_account"
                    text-field="name_description"
                    :state="
                      payoutRequestForm.errors.has('account_number')
                        ? false
                        : null
                    "
                  >
                    <template #first>
                      <b-form-select-option value="">
                        -- Please select an option --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <p v-if="payableBankAccount" class="bg-secondary p-1">
                  {{
                    `${payableBankAccount.account_name} - ${payableBankAccount.account_number} - ${payableBankAccount.bank.name}`
                  }}
                </p>
              </div>
              <div v-else>
                <b-form-group
                  description="Select the bank of the third-party"
                  label="Bank"
                  label-for="bank_id"
                  :invalid-feedback="payoutRequestForm.errors.get('bank_id')"
                  :state="
                    !payoutRequestForm.errors.has('recommendingbank_id_user_id')
                  "
                >
                  <v-select
                    v-model="payoutRequestForm.bank_id"
                    :options="banks"
                    label="name"
                    :filterable="false"
                    @search="vselectSearch"
                    placeholder="Select a bank"
                    :reduce="(bank) => bank.id"
                  >
                    <template #no-options> type to search banks... </template>

                    <template #list-header v-if="vselectSearchResults">
                      <div
                        class="bg-dark border-bottom p-3 d-flex justify-content-between"
                      >
                        <span class="small">
                          Total: {{ vselectSearchResults.total }}<br />
                          Pages: {{ vselectSearchResults.last_page }}<br />
                          Current Page: {{ vselectSearchResults.current_page }}
                        </span>
                        <div class="mb-2">
                          <b-btn
                            :disabled="!vselectSearchResults.prev_page_url"
                            @click="
                              vselectGoToPage(
                                vselectSearchResults.current_page - 1
                              )
                            "
                            variant="info"
                            class="mr-2"
                            size="sm"
                            squared
                          >
                            &laquo; Prev
                          </b-btn>
                          <b-btn
                            :disabled="!vselectSearchResults.next_page_url"
                            @click="
                              vselectGoToPage(
                                vselectSearchResults.current_page + 1
                              )
                            "
                            variant="success"
                            size="sm"
                            squared
                          >
                            Next &raquo;
                          </b-btn>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <b-form-group
                  label="Account Number *"
                  label-for="account_number"
                  :invalid-feedback="
                    payoutRequestForm.errors.get('account_number')
                  "
                  :state="!payoutRequestForm.errors.has('account_number')"
                >
                  <b-form-input
                    id="amount"
                    v-model="payoutRequestForm.account_number"
                    placeholder="Enter the bank account number"
                    type="number"
                    :state="
                      payoutRequestForm.errors.has('account_number')
                        ? false
                        : null
                    "
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </form>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
          <b-button size="sm" variant="success" @click="createPayout()" si>
            <b-spinner
              label="Loading"
              v-if="payoutRequestForm.busy"
              small
            ></b-spinner>
            Create
          </b-button>
        </template>
      </b-modal>

      <!-- Dummy: View modal - To mock the view page -->
      <div id="view-payout-modal"></div>

      <!-- Delete modal -->
      <div
        class="modal fade"
        id="delete-payout-modal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="delete-payout-modal-title">
                Are you sure you want to delete this request
                <code>{{ payoutRequest ? payoutRequest.reference : "" }}</code
                >?
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-dark"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="deleteRecord()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { getToken } from "@/utils/jwt.service";
import $ from "jquery";
import { mapGetters } from "vuex";
import { serialize } from "object-to-formdata";
import roles from "@/mixins/roles";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";

export default {
  name: "PendingPayoutRequests",
  mixins: [vselectPaginationSearch, roles],
  data() {
    return {
      records: null,
      banks: [],
      payoutRequestForm: this.$Form({
        payout_request_sub_category_id: "",
        amount: "",
        reason: "",
        recommending_department: "",
        recommending_user_id: "",
        attachments: [],
        callback_url: "",
        bank_id: "",
        account_number: "",
      }),
      users: [],
      payoutRequest: null,
      payableToUser: false,
      payableDepartment: "",
      payableUsers: [],
      payableBankAccount: null,
      payoutRequestSubCategories: [],
      loading: false,
    };
  },
  watch: {
    "payoutRequestForm.recommending_department": function (role) {
      this.payoutRequestForm.recommending_user_id = "";
      this.fetchUsers(role);
    },
    payableToUser() {
      this.payoutRequestForm.bank_id = "";
      this.payoutRequestForm.account_number = "";
    },
    payableDepartment(role) {
      this.payableUsers = [];
      this.payoutRequestForm.bank_id = "";
      this.payoutRequestForm.account_number = "";
      this.payableBankAccount = null;
      this.fetchPayableUsers(role);
    },
    payableBankAccount(bankAccount) {
      this.payoutRequestForm.bank_id = bankAccount ? bankAccount.bank_id : "";
      this.payoutRequestForm.account_number = bankAccount
        ? bankAccount.account_number
        : "";
    },
  },
  computed: {
    ...mapGetters({
      authUser: "user",
    }),
    departments() {
      return this.$root.userRoles.map((role) => {
        return {
          value: role,
          text: role.toUpperCase(),
        };
      });
    },
  },
  methods: {
    intiateExportRequests() {
      http
        .post(`${endpoints.EXPORT_PAYOUT_REQUESTS}`)
        .then(() => {
          this.$toast.success("Payout request export initiated");
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },

    fetchRecords() {
      this.records = $("#payout-requests-table").DataTable({
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, "All"],
        ],
        processing: true,
        serverSide: true,
        ajax: {
          url: `${url}${endpoints.VIEW_ALL_PAYOUT_REQUESTS}?datatable=1&include=user,recommender,manager,payoutRequestSubCategory,bankAccount`,
          type: "GET",
          beforeSend: function (request) {
            request.setRequestHeader("Accept", "application/json");
            request.setRequestHeader("Authorization", `Bearer ${getToken()}`);
          },
          error: (xhr) => {
            // unauthenticated
            if (xhr.status == 401) {
              this.$router.replace({ path: "login" });
            }

            this.$toast.error(xhr.statusText);
          },
        },
        order: [[9, "desc"]],
        columns: [
          {
            data: "DT_RowIndex",
            orderable: false,
            searchable: false,
          },
          {
            data: "reference",
          },
          {
            data: "status",
            render: function (data) {
              let color = "info";

              switch (data) {
                case "recommendation_approved":
                case "management_approved":
                case "payment_successful":
                  color = "success";
                  break;
                case "recommendation_declined":
                case "management_declined":
                case "payment_failed":
                  color = "danger";
                  break;
              }

              return `<span class="badge badge-${color} p-1">${data.replace(
                "_",
                " "
              )}</span>`;
            },
          },
          {
            data: "payout_request_sub_category",
            orderable: false,
            searchable: false,
            render: (data) => {
              return data ? data.name : "";
            },
          },
          {
            data: "amount",
          },
          {
            data: "bank_account",
            searchable: false,
            orderable: false,
            render: function (data) {
              return `${data?.account_name}`;
            },
          },
          {
            data: "user",
            orderable: false,
            searchable: false,
            render: function (data) {
              return `${data?.firstname} ${data?.lastname}`;
            },
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: function (data) {
              return data.recommender
                ? `${data.recommender.firstname} ${data.recommender.lastname}`
                : `${data.recommending_department}`;
            },
          },
          {
            data: "manager",
            orderable: false,
            searchable: false,
            render: function (data) {
              return data ? `${data?.firstname} ${data?.lastname}` : "";
            },
          },
          {
            data: "created_at",
            searchable: false,
            render: (data) => {
              return this.formatDateTime(data);
            },
          },
          {
            data: "updated_at",
            searchable: false,
            render: (data) => {
              return this.formatDateTime(data);
            },
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data) => {
              let actions = `
                  <button
                    class="btn btn-sm btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#view-payout-modal"
                    data-payout-request-id="${data.id}"
                    data-payout-request-reference="${data.reference}"
                  >
                    View
                  </button>
                `;

              if (
                (data.status === "pending" ||
                  data.status == "recommendation_approved") &&
                data.user_id === this.authUser.id
              ) {
                actions += `
                    <button
                      class="btn btn-sm btn-danger"
                      data-toggle="modal"
                      data-target="#delete-payout-modal"
                      data-payout-request-id="${data.id}"
                      data-payout-request-reference="${data.reference}"
                    >
                      Delete
                    </button>
                  `;
              }

              return `<div class="d-flex">${actions}</div>`;
            },
          },
        ],
      });
    },
    refreshRecords() {
      this.records.ajax.reload();
    },
    fetchPayoutRequestSubCategories() {
      http
        .get(
          `${endpoints.VIEW_ALL_PAYOUT_REQUEST_SUB_CATEGORIES}?fields[payout_request_sub_categories]=id,name`
        )
        .then((response) => {
          this.payoutRequestSubCategories = response;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    fetchUsers(role) {
      http
        .get(`${endpoints.GET_ALL_USERS}?filter[role]=${role}`)
        .then((response) => {
          this.users = response
            .filter((user) => user.id !== this.authUser.id)
            .map((user) => {
              user.name_description = `${user.firstname} ${user.lastname} (${user.email})`;
              return user;
            });
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    fetchPayableUsers(role) {
      http
        .get(
          `${endpoints.GET_ALL_USERS}?filter[role]=${role}&include=bankAccount`
        )
        .then((response) => {
          this.payableUsers = response
            .filter((user) => user.id !== this.authUser.id)
            .filter((user) => user.bank_account != null)
            .map((user) => {
              user.name_description = `${user.firstname} ${user.lastname}`;
              return user;
            });
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    formatFileNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} files selected`;
    },
    vselectComponentSearch(loading, vm) {
      http
        .get(endpoints.GET_ALL_BANKS, {
          params: {
            page: vm.vselectSearchPage,
            "filter[name]": vm.vselectSearchTerm,
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.banks = response.data;
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },
    vselectComponentClearSelection() {
      // this.payoutRequestForm.clear();
    },
    createPayout() {
      this.loading = true;
      this.payoutRequestForm.callback_url = `${window.location.origin}/payout-requests`;

      this.payoutRequestForm
        .post(endpoints.CREATE_PAYOUT_REQUEST, {
          // Transform form data to FormData
          transformRequest: [
            function (data) {
              return serialize(data);
            },
          ],
        })
        .then((response) => {
          this.loading = false;
          this.$toast.success(response);
          this.$bvModal.hide("create-payout-request");
          this.refreshRecords();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    deleteRecord() {
      if (!this.payoutRequest.id) {
        alert("Something is wrong. Contact the engineering team!");
        return;
      }

      http
        .delete(
          endpoints.DELETE_PAYOUT_REQUEST.replace(":id", this.payoutRequest.id)
        )
        .then(() => {
          $("#delete-payout-modal").modal("hide");
          this.$toast.success("Payout request deleted successfully");
          this.refreshRecords();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        });
    },
  },
  mounted() {
    this.fetchRecords();
    this.fetchPayoutRequestSubCategories();

    $("#delete-payout-modal").on("show.bs.modal", (e) => {
      this.payoutRequest = {
        id: e.relatedTarget.getAttribute("data-payout-request-id"),
        reference: e.relatedTarget.getAttribute(
          "data-payout-request-reference"
        ),
      };
    });

    $("#view-payout-modal").on("show.bs.modal", (e) => {
      e.preventDefault();

      this.$router.push({
        name: "Payout Request",
        params: {
          reference: e.relatedTarget.getAttribute(
            "data-payout-request-reference"
          ),
        },
      });
    });
  },
};
</script>
