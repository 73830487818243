import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    isManager() {
      return this.user.role === "management";
    },
    isAdmin() {
      return this.user.role === "admin";
    },
    isAccounts() {
      return this.user.role === "accounts";
    },
    isTech() {
      return this.user.role === "tech";
    },
    isClientServices() {
      return this.user.role === "client services";
    },
    isElderlyServices() {
      return this.user.role === "elderly services";
    },
    isMedicalServices() {
      return this.user.role === "medical services";
    },
    isGanar() {
      return this.user.role === "ganar";
    },
    isDigitalMarketer() {
      return this.user.role === "digital marketing";
    },
  },
};
